import { Route, Routes } from 'react-router-dom'
import Admin from './pages/Admin/Admin'
import DroppedUsers from './pages/DroppedUsers/DroppedUsers'
import GenerateGSTSheet from './pages/GenerateGSTSheet/GenerateGSTSheet'
import GenerateGSTSheetPayPal from './pages/GenerateGSTSheetPaypal/GenerateGSTSheetPayPal'
import Login from './pages/Login/Login'
import NotFound from './pages/NotFound/NotFound'
import PartnersSale from './pages/PremiumSales/PartnersSale'
import PremiumSales from './pages/PremiumSales/PremiumSales'
import ProtectedRoute from './utils/ProtectedRoute'

const App = () => {


    return (
        <div>
            <Routes>
                <Route
                    path='/'
                    element={
                        <Login />
                    }
                />
                <Route element={<ProtectedRoute />}>
                    <Route
                        path='/admin'
                        element={
                            <Admin />
                        }
                    />
                    <Route
                        path='/droppped-users'
                        element={
                            <DroppedUsers />
                        }
                    />
                    <Route
                        path='/premium-sales'
                        element={
                            <PremiumSales />
                        }
                    />
                    <Route
                        path='/partners-sales'
                        element={
                            <PartnersSale />
                        }
                    />
                    <Route
                        path='/generate-gst-sheet-razorpay'
                        element={
                            <GenerateGSTSheet />
                        }
                    />
                    <Route
                        path='/generate-gst-sheet-paypal'
                        element={
                            <GenerateGSTSheetPayPal />
                        }
                    />
                </Route>
                <Route
                    path='*'
                    element={
                        <NotFound />
                    }
                />
            </Routes>
        </div>
    )
}

export default App