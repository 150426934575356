import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTimeSalesStats } from '../../services/operations/premium';
import renderTotals from '../../components/Charts/Ring';
import Statewise from '../../components/SalesTracker/Statewise';
import TotalSales from '../../components/SalesTracker/TotalSales';
import TotalSalesUSD from '../../components/SalesTracker/TotalSalesUSD';

const PremiumSales = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { allTimeSalesStats } = useSelector((state) => state.premium)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllTimeSalesStats())
    }, [dispatch])


    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-white">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex items-start justify-between w-full'>
                            <span className='text-2xl font-bold'>All Time Gross</span>
                            <div className="bg-white  dark:bg-dark_50 shadow-lg rounded-lg p-4">
                                <div className='flex  space-x-4'>
                                    <p className="text-xl  text-gray-600 dark:text-gray-400">
                                        Total Users: {allTimeSalesStats.total_users}
                                    </p>
                                    <p className="text-xl  text-gray-600 dark:text-gray-400">
                                        Total Revenue: {allTimeSalesStats.total_revenue}
                                    </p>
                                    <p className="text-xl  text-gray-600 dark:text-gray-400">
                                        Total GST: {allTimeSalesStats.total_gst}
                                    </p>
                                    <p className="text-xl  text-gray-600 dark:text-gray-400">
                                        Total Profit: {allTimeSalesStats.total_profit}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {renderTotals({ allTimeSalesStats })}
                        <Statewise />
                        <TotalSales />
                        <TotalSalesUSD />

                    </div>

                </main>
            </div>
        </div>
    )
}

export default PremiumSales