import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPayPalGSTSheet} from '../../services/operations/premium';
import toast from 'react-hot-toast';

const GenerateGSTSheetPayPal = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { payPalGstSheets } = useSelector((state) => state.premium)
    const [file, setFile] = useState(null);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllPayPalGSTSheet())
    }, [dispatch])
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        const toastId = toast.loading("Loading...")
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/premium/generate-gst-sheet-paypal`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const { url } = response.data;
            window.open(url, '_blank');
            dispatch(getAllPayPalGSTSheet())
            setFile(null)
        } catch (error) {
            toast.error('Error uploading file');
        }
        toast.dismiss(toastId)
    };

    const handleDownloadSheet = (url) => {
        window.open(url, '_blank');
    }

    // console.log(razorPayGstSheets)


    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-white">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                    <span className='text-2xl font-bold'>Generate Paypal GST Sheet</span>
                        <form className='mt-8' onSubmit={handleSubmit}>
                            <input className='form-input' type="file" accept=".csv, .xlsx" onChange={handleFileChange} />
                            <button className='btn ml-4' type="submit">Upload</button>
                        </form>
                        <div className='mt-10'>
                            <div className="  font-primary  relative">
                                <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                                    <div className="overflow-x-auto">
                                        <table className="table-auto w-full  divide-y  divide-gray-200">
                                            <thead className="text-xs uppercase  text-[#8C8C8C] ">
                                                <tr>
                                                    <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                        <div className="font-semibold text-center">No.</div>
                                                    </th>
                                                    <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/2 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                                        <div className="font-semibold flex items-start">Month</div>
                                                    </th>
                                                    <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                        <div className="font-semibold text-center">Download</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            {
                                                payPalGstSheets && payPalGstSheets?.length > 0 && payPalGstSheets?.map((data, index) => (
                                                    <tbody className="text-[13px]">
                                                        <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                                                            <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                                                    {index + 1}.
                                                                </div>
                                                            </td>
                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                    {data?.month}
                                                                </div>
                                                            </td>

                                                            <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">

                                                                    <button onClick={() => handleDownloadSheet(data?.link)} className='btn bg-red-500 border-red-500 text-white  transation-all duration-300 ease-in-out'>Download</button>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ))
                                            }
                                        </table>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default GenerateGSTSheetPayPal