import React from 'react'
import LineChart from '../Charts/LineChart';

const PartnersAllTimeSale = ({ data }) => {

    let chartData
    if (data) {

        chartData = {
            labels: data.map(item => {
                const date = new Date(item.sale_date);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }),
            datasets: [
                // Indigo line
                {
                    data: data.map(item => item.total_amount),
                    fill: true,
                    borderWidth: 2,
                    tension: 0,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointBackgroundColor: '#0891b2',
                    clip: 20,
                },
                // Gray line
                {
                    data: data.map(item => item.total_sales),
                    borderWidth: 2,
                    tension: 0,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointBackgroundColor: '#ea580c',
                    clip: 20,
                },
                
            ],
        };
    }

    return (
        <div className="flex-grow w-full h-full">
            <LineChart width={800} data={chartData} />
        </div>
    )
}

export default PartnersAllTimeSale