import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StatewiseSalesChart = ({ data }) => {

   
    const chartData = {
        labels: data.map(item => item.location),
        datasets: [
            {
                label: 'Total Revenue',
                data: data.map(item => item.total_revenue),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                hoverBackgroundColor: 'rgba(75,192,192,0.4)',
                hoverBorderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
            {
                label: 'Total GST',
                data: data.map(item => item.total_gst),
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 1)',
                hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
                hoverBorderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            },
            {
                label: 'Total Profit',
                data: data.map(item => item.total_profit),
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 1)',
                hoverBackgroundColor: 'rgba(255, 159, 64, 0.4)',
                hoverBorderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1,
            },
        ],
    };
    return (
        <div className="p-4 bg-white dark:bg-dark_40 shadow-lg rounded-lg ">
            <Bar data={chartData} />
        </div>
    );
};

export default StatewiseSalesChart;
