import React, { useEffect, useState } from 'react'
import StatewiseSalesChart from '../Charts/StateWiseSalesChart';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesInState } from '../../services/operations/premium';

const Statewise = () => {

    const { statewiseSales } = useSelector((state) => state.premium)

    const [startDate, setStartDate] = useState("2024-08-07");
    const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 19).replace('T', ' '));


    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSalesInState(startDate, endDate))
    }, [dispatch, startDate, endDate])
    
    const sortedData = [...statewiseSales].sort((a, b) => a.total_sales - b.total_sales);

    const top3 = sortedData.slice(-3);   // Last 3 items
    const bottom3 = sortedData.slice(0, 3);   // First 3 items
    return (
        <>
            <h3 className="text-2xl font-semibold mb-2 text-gray-800 dark:text-gray-100">
                Statewise Sales
            </h3>
            <div className="bg-[#fafafa] shadow-lg rounded-lg dark:bg-dark_50  p-8">
                <div className='flex justify-between items-start'>

                    <div className='flex items-start space-x-10 mb-8'>
                        <div>
                            <p className='text-2xl'>Most Popular in</p>
                            {top3 && top3?.map((item, index) => (
                                <div className='mt-2' key={index}>
                                    {index + 1}. {item.location}
                                </div>
                            ))}
                        </div>
                        <div>
                            <p className='text-2xl'>Least Popular in</p>
                            {bottom3 && bottom3?.map((item, index) => (
                                <div className='mt-2' key={index}>
                                    {index + 1}. {item.location}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <input
                            type="date"
                            className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <input
                            type="date"
                            className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                            value={endDate.split(' ')[0]}
                            onChange={(e) => {
                                const selectedDate = new Date(e.target.value);
                                setEndDate(selectedDate.toISOString().slice(0, 10) + ' ' + new Date().toISOString().slice(11, 19));
                            }}

                        />
                    </div>
                </div>
                <StatewiseSalesChart data={statewiseSales} />
            </div>
        </>
    )
}

export default Statewise