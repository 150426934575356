import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { getPartnerSales } from '../../services/operations/premium';
import PartnersAllTimeSale from '../../components/SalesTracker/PartnersAllTimeSale';
import { calculateTotalsForPartnerUSD, formatCurrency } from '../../utils/Utils';

const PartnersSale = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [startDate, setStartDate] = useState("2024-08-07");
    const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 19).replace('T', ' '));

    const dispatch = useDispatch()
    const { partnersSales } = useSelector((state) => state.premium)

    useEffect(() => {
        dispatch(getPartnerSales(1, 'Interview Ready', startDate, endDate))
    }, [dispatch, startDate, endDate])


    const formattedData = {
        total_revenue_inr: formatCurrency(partnersSales?.totals?.total_revenue_inr),
        total_gst_inr: formatCurrency(partnersSales?.totals?.total_gst_inr),
        total_profit_inr: formatCurrency(partnersSales?.totals?.total_profit_inr),
        total_sales: formatCurrency(partnersSales?.totals?.total_sales_inr)
    };

    const partnerUSDStats = calculateTotalsForPartnerUSD(partnersSales && partnersSales?.dayswiseUSD, 83.99)
    // console.log(partnerUSDStats)
    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-white">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex justify-between isolate'>
                            <span className='text-2xl font-bold'>TUF+ Partners Sales INR</span>

                            <div className="flex items-center space-x-4">
                                <input
                                    type="date"
                                    className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <input
                                    type="date"
                                    className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={endDate.split(' ')[0]}
                                    onChange={(e) => {
                                        const selectedDate = new Date(e.target.value);
                                        setEndDate(selectedDate.toISOString().slice(0, 10) + ' ' + new Date().toISOString().slice(11, 19));
                                    }}
                                />
                            </div>
                        </div>
                        <div className='mt-8 bg-dark_50 mb-8 flex justify-center items-center rounded-lg p-4 w-full'>
                            <div className='flex  space-x-8'>
                                <p className="text-2xl  text-gray-600 dark:text-gray-400">
                                    Total Users: {formattedData.total_sales}
                                </p>
                                <p className="text-2xl  text-gray-600 dark:text-gray-400">
                                    Total Revenue: {formattedData.total_revenue_inr}
                                </p>
                                <p className="text-2xl  text-gray-600 dark:text-gray-400">
                                    Total GST: {formattedData.total_gst_inr}
                                </p>
                                <p className="text-2xl  text-gray-600 dark:text-gray-400">
                                    Total Profit: {formattedData.total_profit_inr}
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col col-span-full sm:col-span-6 xl:col-span-4 w-full mt-10 bg-white dark:bg-dark_50 p-4 rounded-lg'>
                            <PartnersAllTimeSale data={partnersSales && partnersSales?.daywises} />
                        </div>
                        <div className='flex justify-between isolate mt-20'>
                            <span className='text-2xl font-bold'>TUF+ Partners Sales IN USD</span>

                            <div className="flex items-center space-x-4">
                                <input
                                    type="date"
                                    className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <input
                                    type="date"
                                    className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={endDate.split(' ')[0]}
                                    onChange={(e) => {
                                        const selectedDate = new Date(e.target.value);
                                        setEndDate(selectedDate.toISOString().slice(0, 10) + ' ' + new Date().toISOString().slice(11, 19));
                                    }}
                                />
                            </div>
                        </div>
                        <div className='mt-8 bg-dark_50 mb-8 flex justify-center items-center rounded-lg p-4 w-full'>
                            <div className='flex  space-x-8'>
                                <p className="text-2xl  text-gray-600 dark:text-gray-400">
                                    Total Users: {partnerUSDStats.totalUsers}
                                </p>
                                <p className="text-2xl  text-gray-600 dark:text-gray-400">
                                    Total profit: ${partnerUSDStats.totalProfit}
                                </p>
                                <p className="text-2xl  text-gray-600 dark:text-gray-400">
                                    Total profit in INR: {partnerUSDStats.totalProfitINR}
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col col-span-full sm:col-span-6 xl:col-span-4 w-full mt-10 bg-white dark:bg-dark_50 p-4 rounded-lg'>
                            <PartnersAllTimeSale data={partnersSales && partnersSales?.dayswiseUSD} />
                        </div>
                    </div>

                </main>
            </div>
        </div>
    )
}


export default PartnersSale