import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slices/authSlice";
import taPortalReducer  from "../slices/taPortalSlice";
import premiumReducer  from "../slices/premiumSlice";

const rootReducer = combineReducers({
    auth: authReducer,
    premium: premiumReducer,
    taPortal: taPortalReducer,
});

export default rootReducer;
