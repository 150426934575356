const BASE_URL = process.env.REACT_APP_BACKEND_URL
const BASE_URL_2 = process.env.REACT_APP_BACKEND_URL_PREMIUM


export const authEndpoints = {
    LOGIN_IN: BASE_URL + '/auth/login',
    LOGOUT_IN: BASE_URL + '/auth/logout',
}
export const premiumEndpoints = {
    GET_PREMIUM_GST_SHEET: BASE_URL + '/premium/get-razorpay-gst-sheet',
    GET_PREMIUM_GST_SHEET_PAYPAL: BASE_URL + '/premium/get-razorpay-gst-sheet-paypal'
}
export const salesEndpoint = {
    GET_ALL_TIME_SALES_STATS: BASE_URL_2 + '/revenue/get-allTime-stats',
    SALES_IN_STATE: BASE_URL_2 + '/revenue/sales-in-state',
    TOTAL_PARTNER_SALES: BASE_URL_2 + '/revenue/total-partner-sales/',
    TOTAL_SALES: BASE_URL_2 + '/revenue/total-sales/',
}
