import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { convertToNumeric } from '../../utils/Utils';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const renderTotals = ({ allTimeSalesStats }) => {
    if (allTimeSalesStats) {
        // Data for INR Totals
        const numericData = convertToNumeric(allTimeSalesStats);
        const inrData = {
            labels: ['Total Revenue', 'Total GST', 'Total Profit'],
            datasets: [
                {
                    label: 'INR Totals',
                    data: [
                        numericData.total_revenue,
                        numericData.total_gst,
                        numericData.total_profit,
                    ],
                    backgroundColor: ['#4CAF50', '#FFC107', '#2196F3'],
                    hoverBackgroundColor: ['#388E3C', '#FFA000', '#1976D2'],
                },
            ],
        };

        return (
            <div className="flex font-primary justify-center md:justify-around mb-8 space-x-4 mt-8">
                <div className="flex  flex-col w-full items-center">
                    <div className="bg-white w-full flex justify-center items-center  dark:bg-dark_50 shadow-lg rounded-lg p-8">
                        <div className="flex  justify-center w-64 h-64">
                            <Doughnut data={inrData} />
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
    return null;
};

export default renderTotals;
