import React, { useEffect, useState } from 'react'
import LineChart from '../Charts/LineChart';
import { useDispatch, useSelector } from 'react-redux';
import { getSales } from '../../services/operations/premium';
import {  calculateTotalsForINR, formatCurrency, getISTDateTime } from '../../utils/Utils';

const TotalSales = () => {

    const [startDate, setStartDate] = useState("2024-08-07");
    const [endDate, setEndDate] = useState(getISTDateTime());

    const { totalSales } = useSelector((state) => state.premium)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSales(1, startDate, endDate))
    }, [dispatch, startDate, endDate])

    let chartData
    if (totalSales && totalSales?.daywises) {

        chartData = {
            labels: totalSales?.daywises?.map(item => {
                const utcDate = new Date(item.sale_date);

                // Convert UTC to IST (UTC+5:30)
                const istDate = new Date(utcDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));

                const day = String(istDate.getDate()).padStart(2, '0');
                const month = String(istDate.getMonth() + 1).padStart(2, '0');
                const year = istDate.getFullYear();

                return `${day}-${month}-${year}`;
            }),
            datasets: [
                // Indigo line
                {
                    data: totalSales?.daywises?.map(item => item.total_amount),
                    fill: true,
                    borderWidth: 2,
                    tension: 0,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointBackgroundColor: '#0891b2',
                    clip: 20,
                },
                // Gray line
                {
                    data: totalSales?.daywises?.map(item => item.total_sales),
                    borderWidth: 2,
                    tension: 0,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointBackgroundColor: '#ea580c',
                    clip: 20,
                },

            ],
        };
    }

    // console.log(totalSales?.daywises)
    // totals: {

    //     total_revenue_inr: 230059,

    //     total_gst_inr: 35094,

    //     total_profit_inr: 194965,

    //     total_sales: 44

    //   },

    // const formattedData = {
    //     total_revenue_inr: formatCurrency(totalSales?.totals?.total_revenue_inr),
    //     total_gst_inr: formatCurrency(totalSales?.totals?.total_gst_inr),
    //     total_profit_inr: formatCurrency(totalSales?.totals?.total_profit_inr),
    //     total_sales: formatCurrency(totalSales?.totals?.total_sales)
    // };

    const inrStats = calculateTotalsForINR(totalSales && totalSales?.daywises)
    return (
        <div className='mt-20'>
            <span className='text-2xl font-bold'>TUF+ INR</span>
            <div className='flex justify-between items-center mt-10'>
                <div className="flex items-center space-x-4">
                    <input
                        type="date"
                        className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                        type="date"
                        className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                        value={endDate.split(' ')[0]}
                        onChange={(e) => {
                            const selectedDate = new Date(e.target.value);
                            const currentTime = new Date().toLocaleTimeString('en-GB', {
                                timeZone: 'Asia/Kolkata',
                                hour12: false,
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            });

                            setEndDate(
                                `${selectedDate.toISOString().slice(0, 10)} ${currentTime}`
                            );
                        }}
                    />
                </div>
                <div>
                    <div className="bg-white  dark:bg-dark_40 border-zinc-300 dark:border-zinc-800 rounded-lg p-4">
                        <div className='flex  space-x-8'>
                            <p className="text-xl  text-gray-600 dark:text-gray-400">
                                Total Users: {inrStats.totalUsers}
                            </p>
                            <p className="text-xl  text-gray-600 dark:text-gray-400">
                                Total Revenue: {formatCurrency(inrStats.totalRevenue)}
                            </p>
                            <p className="text-xl  text-gray-600 dark:text-gray-400">
                                Total GST: {formatCurrency(inrStats.gstAmount)}
                            </p>
                            <p className="text-xl  text-gray-600 dark:text-gray-400">
                                Total Profit: {formatCurrency(inrStats.totalProfit)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-grow w-full h-full mt-4 dark:bg-dark_40">
                <LineChart currency={'INR'} width={800} data={chartData} />
            </div>
        </div>
    )
}

export default TotalSales