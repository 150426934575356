import React, { useRef, useEffect } from 'react';
import {
    Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

function LineChart({
    data,
    width,
    height,
    currency
}) {
    const canvas = useRef(null);

    useEffect(() => {
        const ctx = canvas.current;

        // Create gradient for the first dataset
        const gradient1 = ctx.getContext('2d').createLinearGradient(0, 0, 0, 400);
        gradient1.addColorStop(0, 'rgba(192, 38, 211, 0.5)'); // Lighter shade at the top
        gradient1.addColorStop(1, 'rgba(192, 38, 211, 0)');  // Transparent at the bottom

        // Create gradient for the second dataset
        const gradient2 = ctx.getContext('2d').createLinearGradient(0, 0, 0, 400);
        gradient2.addColorStop(0, 'rgba(101, 163, 13, 0.5)'); // Lighter green shade at the top
        gradient2.addColorStop(1, 'rgba(101, 163, 13, 0)');  // Transparent at the bottom
        
        const chart = new Chart(ctx, {
            type: 'line',
            data: {
                ...data,
                datasets: [
                    {
                        ...data?.datasets[0],
                        fill: true,
                        backgroundColor: gradient1,  // Gradient for the first dataset
                        borderColor: '#8b5cf6',  // Bright purple for the line
                        borderWidth: 3,  // Thicker line
                        tension: 0.4,  // Curved lines
                        pointRadius: 0,  // Hide points by default
                        pointHoverRadius: 6,  // Show larger points on hover
                        yAxisID: 'y1',  // First dataset uses the first Y-axis
                    },
                    {
                        ...data?.datasets[1],
                        fill: true,
                        backgroundColor: gradient2,  // Gradient for the second dataset
                        borderColor: '#65a30d',  // Green line color for second dataset
                        borderWidth: 3,  // Thicker line
                        tension: 0.4,  // Curved lines
                        pointRadius: 0,  // Hide points by default
                        pointHoverRadius: 6,  // Show larger points on hover
                        yAxisID: 'y2',  // Second dataset uses the second Y-axis
                    }
                ]
            },
            options: {
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 20,
                        bottom: 20
                    }
                },
                scales: {
                    y1: {
                        beginAtZero: true,
                        position: 'left',
                        grid: {
                            color: 'rgba(255, 255, 255, 0.1)',  // Light grid lines
                        },
                        ticks: {
                            color: '#fff',  // White Y-axis labels
                        },
                    },
                    y2: {
                        beginAtZero: true,
                        position: 'right',
                        grid: {
                            display: false,  // Hide grid for second Y-axis
                        },
                        ticks: {
                            color: '#fff',  // White Y-axis labels
                        },
                    },
                    x: {
                        type: 'time',
                        time: {
                            parser: 'DD-MM-YYYY',  // Adjust time parser for DD-MM-YYYY format
                            unit: 'day',  // Display data per day
                        },
                        grid: {
                            display: false,  // Hide grid for X-axis
                        },
                        ticks: {
                            color: '#fff',  // White X-axis labels
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        enabled: true,  // Enable tooltips
                        mode: 'nearest',
                        intersect: false,
                        callbacks: {
                            title: (context) => context[0].label,  // Show date as the title
                            label: function (context) {
                                if (context.dataset.yAxisID === 'y1') {
                                    return `${currency === 'INR'? '₹' : '$'}${context.parsed.y.toLocaleString()}`;  // INR formatting for dataset[0]
                                } else {
                                    return `${context.parsed.y} sales`;  // Number of sales for dataset[1]
                                }
                            },
                        },
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Customize tooltip background
                        titleColor: '#fff',  // White title color in tooltips
                        bodyColor: '#fff',  // White body text in tooltips
                        borderColor: '#4b5563',
                        borderWidth: 1,
                        displayColors: false,  // Hide color box in tooltips
                    },
                    legend: {
                        display: false,
                    },
                },
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                maintainAspectRatio: false,
                resizeDelay: 200,
            },
        });

        return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <canvas ref={canvas} width={width} height={height}></canvas>
    );
}

export default LineChart;
